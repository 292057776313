import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import { useI18next } from 'gatsby-plugin-react-i18next';

import dropdownArrow from 'images/dropdown-arrow.png';
import { AVAILABLE_LOKALISE_LANGUAGES } from 'utils/constants';

import NavigationColor from 'utils/constants/NavigationColor';

const Container = styled.div`
  position: relative;
`;

const SelectedOption = styled.button<{
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}>`
  background-color: transparent;
  color: ${({ variant }) =>
    variant === NavigationColor.DARK ? '#000' : '#424242'};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 0;
  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position: center right;
  margin: 0;
`;

const LangFlag = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 4px;
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  padding: 8px;
  background-color: #f7efe0;
  border-radius: 8px;
  box-shadow: 1px 2px 18px rgb(0 0 0 / 25%);
  transition: all 300ms ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  position: absolute;
  z-index: 2;
`;

const Option = styled.li<{ fadedClr: boolean }>`
  text-align: left;
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 4px 4px 4px 0;
  border-radius: 6px;
  color: ${({ theme, fadedClr }) =>
    fadedClr ? theme.colors.fadedTertiary : theme.colors.tertiary};
  background-color: #f7efe0;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

interface Props {
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
  isInNavbar?: boolean;
}

function findSelectedLangObj(language: string) {
  const obj = AVAILABLE_LOKALISE_LANGUAGES.filter(
    (lang) => lang.abbreviation === language,
  )[0];

  return obj;
}

const LanguageDropdown = ({ variant, isInNavbar = true }: Props) => {
  const { t, changeLanguage, language } = useI18next();

  const selectedLngObj = findSelectedLangObj(language);

  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(selectedLngObj);

  const ref = useRef(null);

  const toggleDropdown = () =>
    setDropdownIsOpen((previousState) => !previousState);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  const handleLangClick = (lang: string) => {
    if (lang === language) {
      toggleDropdown();
      return;
    }

    changeLanguage(lang);
    const newLangObj = findSelectedLangObj(lang);
    setSelectedOption(newLangObj);
    toggleDropdown();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={ref}>
      <SelectedOption
        type="button"
        variant={variant}
        onClick={toggleDropdown}
        data-testid="toggle-dropdown-btn"
      >
        <LangFlag src={selectedOption.flagIcon} alt="" />
        {!isInNavbar
          ? t(selectedOption.language)
          : selectedOption.abbreviation.toUpperCase()}
      </SelectedOption>
      {dropdownIsOpen && (
        <OptionsList data-testid="options-list" isOpen={dropdownIsOpen}>
          {AVAILABLE_LOKALISE_LANGUAGES.map((lang, idx) => (
            <Option
              onClick={() => handleLangClick(lang.abbreviation)}
              key={lang.abbreviation}
              data-testid={lang.abbreviation}
              fadedClr={isInNavbar && lang === selectedOption}
            >
              <LangFlag src={lang.flagIcon} alt="" />
              {t(lang.language)}
            </Option>
          ))}
        </OptionsList>
      )}
    </Container>
  );
};

export default LanguageDropdown;
