import FlagProvider from '@unleash/proxy-client-react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

const config = {
  url: process.env.GATSBY_UNLEASH_URL,
  clientKey: process.env.GATSBY_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: process.env.GATSBY_UNLEASH_APP_NAME,
  environment: process.env.GATSBY_UNLEASH_ENVIRONMENT,
};

const wrapRootElement = ({ element }) => {
  return (
    <FlagProvider config={config}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </FlagProvider>
  );
};

export default wrapRootElement;
