import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import { useTranslation } from 'react-i18next';
import LinkedinIcon from '../../images/LinkedinIcon';
import LogoFooter from '../../images/LogoFooter';
import NavigationColor from 'utils/constants/NavigationColor';
import { Link } from 'gatsby-plugin-react-i18next';

import LanguageDropdown from 'components/LanguageDropdown';

export const ABOUT_US = [
  {
    key: 'home.Header.Invest',
    href: '/invest',
  },
  {
    key: 'home.Footer.How_it_works',
    href: '/how-it-works',
  },
  {
    key: 'home.Footer.Security',
    href: '/security',
  },
];

export const LEGAL_LINKS = [
  {
    key: 'home.Footer.User_Agreement',
    href: 'home.Footer.User_Agreement.link',
  },
  {
    key: 'security.Footer.Privacy_Policy',
    href: 'home.Footer.Privacy_Policy.link',
  },
  {
    key: 'home.Footer.Privacy_Policy.risk',
    href: 'home.Footer.Privacy_Policy.risk.link',
  },
];

export const SOCIAL_LIST = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/sundaymarketplace/',
    icon: <LinkedinIcon />,
  },
];

const Container = styled.div`
  padding: 40px 120px;

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    padding: 40px 24px;
  }
`;

const Divider = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.tertiary};
  opacity: 0.1;
  margin: 24px 0;
`;

const LayoutWrapper = styled.div`
  display: flex;
  gap: 200px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 0;
  }
`;

const CompanyLogo = styled.div`
  svg {
    width: 124px;
    height: auto;
  }
`;

const FooterList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ::before {
    content: attr(aria-label);
    font-size: ${rem(12)};
    line-height: ${rem(24)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    color: ${(props) => props.theme.colors.tertiary};
    opacity: 0.7;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    &:not(:first-of-type) {
      margin-top: 48px;
    }

    &:first-of-type {
      margin-top: 18px;
    }
  }

  li {
    font-size: ${rem(14)};
    line-height: ${rem(21)};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.tertiary};

    transition: all 150ms ease-in-out;

    svg:hover {
      path:first-of-type {
        transition: all 150ms ease-in-out;
        fill: #c8a259;
      }
    }

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StylingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    gap: 0;
  }
`;

const Disclaimer = styled.p`
  font-size: ${rem(11)};
  line-height: ${rem(21)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => props.theme.colors.tertiary};
  opacity: 0.7;
`;

const LanguageDropdownWrapper = styled.div`
  display: block;
  margin-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

interface Props {
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}

const Footer = ({ variant }: Props) => {
  const { t } = useTranslation();
  const appVersion = process.env.GATSBY_APP_VERSION;

  return (
    <footer>
      <Container>
        <LayoutWrapper>
          <div style={{ maxWidth: '250px' }}>
            <Link to="/">
              <CompanyLogo>
                <LogoFooter />
              </CompanyLogo>
            </Link>
            <Disclaimer style={{ marginTop: '22px' }}>
              {t('home.Footer.trademark')}
            </Disclaimer>
            <Disclaimer style={{ marginTop: '22px' }}>
              v {appVersion || '0.0.0'}
            </Disclaimer>
            <LanguageDropdownWrapper>
              <LanguageDropdown variant={variant} isInNavbar={false} />
            </LanguageDropdownWrapper>
          </div>
          <FooterList aria-label={t('home.Footer.about_us')}>
            {ABOUT_US.map((item) => (
              <li key={item.key}>
                <Link to={item.href}>{t(item.key)}</Link>
              </li>
            ))}
          </FooterList>
          <FooterList aria-label={t('home.Footer.legal_links')}>
            {LEGAL_LINKS.map((item) => (
              <li key={item.key}>
                <a href={t(item.href)} target="_blank">
                  {t(item.key)}
                </a>
              </li>
            ))}
          </FooterList>
          <StylingWrapper>
            <FooterList aria-label={t('home.Footer.support')}>
              <li>
                <a href="mailto:info@mygoldenmarketplace.com">
                  {t('how_it_works.Footer.info@mygoldenmarketplace.com')}
                </a>
              </li>
            </FooterList>
            <FooterList aria-label={t('home.Footer.social_media')}>
              <Flex>
                {SOCIAL_LIST.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} target="_blank" title={item.name}>
                      {item.icon}
                    </a>
                  </li>
                ))}
              </Flex>
            </FooterList>
          </StylingWrapper>
        </LayoutWrapper>
        <Divider />
        <Disclaimer>{t('home.Footer.legal_note')}</Disclaimer>
      </Container>
    </footer>
  );
};

export default Footer;
