import flagSpain from 'images/flagSpain.png';
import flagEstonia from 'images/flagEstonia.png';
import flagUk from 'images/flagUk.png';

export const AVAILABLE_LOKALISE_LANGUAGES = [
  {
    abbreviation: 'en',
    language: 'menu.language_dropdown.english',
    flagIcon: flagUk,
  },
  {
    abbreviation: 'es',
    language: 'menu.language_dropdown.spanish',
    flagIcon: flagSpain,
  },
  {
    abbreviation: 'et',
    language: 'menu.language_dropdown.estonian',
    flagIcon: flagEstonia,
  },
];
