const theme = {
  colors: {
    primary: '#C8A259',
    secondary: '#FCF9F3',
    tertiary: '#424242',
    fadedTertiary: '#4242424d',
    white: '#FFFFFF',
    black: '#000000',
  },
  fonts: {
    body: 'Poppins, sans-serif',
  },
  fontWeights: {
    normal: 300,
    semibold: 400,
    bold: 600,
  },
  breakpoints: {
    tablet: '768px',
    laptop: '1024px',
    desktop: '1280px',
  },
};

export const breakpoints = {
  tablet: '768px',
  laptop: '1024px',
  desktop: '1280px',
};

export default theme;
