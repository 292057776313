import wrapPageElement from './config/wrapPageElement';
import wrapRootElement from './config/wrapRootElement';
import { supportedLanguages } from './src/utils/constants/supportedLanguages.ts';

const defaultLanguage = 'en';
const browserLanguage = window.navigator.language.slice(0, 2);

const isBaseUrl =
  window.location.pathname === '/' ||
  window.location.pathname === '/index.html';

if (supportedLanguages.includes(browserLanguage) && isBaseUrl) {
  window.location.href = `/${browserLanguage}/`;
} else if (isBaseUrl) {
  window.location.href = `/${defaultLanguage}/`;
}

export { wrapPageElement, wrapRootElement };
