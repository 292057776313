import React from "react";

function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      fill="none"
      viewBox="0 0 27 26"
    >
      <path
        fill="#424242"
        d="M22.727 0H4.854A4.062 4.062 0 00.79 4.063v17.875A4.062 4.062 0 004.853 26h17.874a4.062 4.062 0 004.063-4.063V4.063A4.062 4.062 0 0022.727 0z"
      ></path>
      <path
        fill="#fff"
        d="M10.215 19.5H7.453v-8.694h2.762V19.5zM8.834 9.588c-.894 0-1.544-.65-1.544-1.544S8.021 6.5 8.834 6.5c.894 0 1.543.65 1.543 1.544 0 .893-.65 1.543-1.543 1.543zM20.29 19.5h-2.762v-4.713c0-1.38-.57-1.787-1.382-1.787-.812 0-1.625.65-1.625 1.869V19.5H11.76v-8.694h2.6v1.219c.243-.569 1.219-1.463 2.6-1.463 1.544 0 3.169.894 3.169 3.575V19.5h.162z"
      ></path>
    </svg>
  );
}

export default LinkedinIcon;
