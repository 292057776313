import React from 'react';
import 'styles/global.css';
import { ThemeProvider } from 'styled-components';

import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';
import theme from 'theme';
import NavigationColor from 'utils/constants/NavigationColor';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <Footer variant={NavigationColor.DARK} />
      <CookieBanner />
    </ThemeProvider>
  );
};

export default Layout;
